.order-header,
.order-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-left: 300px;
  margin-right: 300px;
  border: 1px solid rgba(165, 165, 165, 0.596);

  button {
    margin-left: 15px;
  }
}
.order-footer {
  padding: 20px;
  margin-left: 300px;
  margin-right: 300px;
  border: 1px solid rgba(165, 165, 165, 0.596);
}
.disabled-link {
  pointer-events: none;
}
